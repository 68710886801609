export class WorkerPool {
  readonly workerScript: string;
  private pool: Worker[];
  private queue: { data: any, resolve: (any) => void, reject: (any) => void }[];
  onMessageCallback: (e: MessageEvent) => void;
  onErrorCallback: (e: ErrorEvent) => void;
  private taskID = 0;
  private resolvers = new Map<number, (any) => void>();
  private rejectors = new Map<number, (any) => void>();

  constructor(workerScript, poolSize, onMessageCallback?, onErrorCallback?) {
    this.workerScript = "";
    this.pool = [];
    this.queue = [];
    this.onMessageCallback = onMessageCallback;
    this.onErrorCallback = onErrorCallback;

    for (let i = 0; i < poolSize; i++) {
      let worker;
      if (workerScript === "filename")
        worker = new Worker(new URL("../filename.worker.ts", import.meta.url));
      if (workerScript === "image")
        worker = new Worker(new URL("../image.worker.ts", import.meta.url));
      if (workerScript === "renderer")
        worker = new Worker(new URL("../renderer.worker.ts", import.meta.url));
      if (workerScript === "imageSync")
        worker = new Worker(new URL("./sync.worker.ts", import.meta.url));
      this.pool.push(worker);
    }
  }

  private _onWorkerMessage(e: MessageEvent, taskID: number) {
    if (e.data.error) {
      this.onErrorCallback?.(e.data.error);
      this.rejectors.get(taskID)(e.data.error);
    }
    else {
      this.onMessageCallback?.(e);
      this.resolvers.get(taskID)(e.data);
    }
    this.cycle(taskID, e.target as Worker);
  }

  private _onWorkerError(e: ErrorEvent, taskID: number) {
    this.onErrorCallback?.(e);
    this.rejectors.get(taskID)(e);
    // this.cycle(taskID, e.target as Worker);
  }

  private cycle(taskID: number, worker: Worker) {
    this.resolvers.delete(taskID);
    this.rejectors.delete(taskID);
    if (this.queue.length) {
      const nextTask = this.queue.shift();
      this.runTask(worker, nextTask.data, nextTask.resolve, nextTask.reject);
    } else {
      this.pool.push(worker);
    }
  }

  init(offscreenCanvases) {
    this.pool.forEach((worker, i) =>
      worker.postMessage(
        { type: "init", payload: { canvas: offscreenCanvases[i] } },
        [offscreenCanvases[i]]
      )
    );
  }

  addTask(data, priority = false) {
    return new Promise((resolve, reject) => {
      const availableWorker = this.pool.pop();
      if (availableWorker) {
        this.runTask(availableWorker, data, resolve, reject);
      } else {
        if (priority) {
          this.queue.unshift({ data, resolve, reject });
        } else {
          this.queue.push({ data, resolve, reject });
        }
      }
    });
  }

  private runTask(worker: Worker, data, resolve, reject) {
    const tid = ++this.taskID;
    this.resolvers.set(tid, resolve);
    this.rejectors.set(tid, reject);
    worker.onmessage = (e) => this._onWorkerMessage(e, tid);
    // worker.onerror = (e) => this._onWorkerError(e, tid);
    worker.postMessage(data);
  }

  terminate() {
    this.pool.forEach((worker) => worker.terminate());
    this.queue.length = 0;
  }
}
