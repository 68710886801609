// db.ts
import Dexie, { Collection, IndexableType, Table } from "dexie";
import {
  LocalizerData,
  upgradeLocalizerData,
} from './rewrite/types/ImagingTypes';

export interface clinic_user_retinal_images {
  clinic_user_id: number;
  eye: string;
  type: string;
  filepath: string;
  capture_date: string;
  sha1: string;
  metadata: any;
  sub_images: {
    [key: string]: { x: number; y: number; width: number; height: number };
  };
  timestamp: string;
}

export interface filepath_to_png {
  filepath: string;
  clinic_user_id: number;
  png: ArrayBuffer;
}

export interface localizer_state {
  clinic_user_id: number;
  json: LocalizerData;
}

export class VVP_DB extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  clinic_user_retinal_images!: Table<clinic_user_retinal_images>;
  filepath_to_png!: Table<filepath_to_png>;
  localizer_state!: Table<localizer_state>;

  cachedUser: number = 0;

  constructor() {
    super("clinic_user_images");
    this.version(1).stores({
      clinic_user_retinal_images:
        "&filepath, clinic_user_id, eye, type, capture_date",
      filepath_to_png: "&filepath, clinic_user_id",
      localizer_state: "&clinic_user_id",
    });
    this.version(2).stores({
      clinic_user_retinal_images:
        "&filepath, clinic_user_id, eye, type, capture_date",
      filepath_to_png: "&filepath, clinic_user_id",
      localizer_state: "&clinic_user_id",
    }).upgrade(tx => {
      return tx.table('clinic_user_retinal_images').toCollection().modify(row => {
        row.filepath = row.clinic_user_id + '/' + row.filepath;
      });
    }).upgrade(tx => {
      return tx.table('filepath_to_png').toCollection().modify(row => {
        row.filepath = row.clinic_user_id + '/' + row.filepath;
      });
    });
    this.version(3).stores({
      clinic_user_retinal_images:
        "&filepath, clinic_user_id, capture_date, timestamp",
      filepath_to_png: "&filepath, clinic_user_id",
      localizer_state: "&clinic_user_id",
    }).upgrade(tx => {
      return tx.table('clinic_user_retinal_images').toCollection().modify(row => {
        row.capture_date = row.capture_date.slice(0, 10);
        row.timestamp = row.timestamp.slice(0, 19).replace('T', ' ');
      });
    }).upgrade(tx => {
      return tx.table('localizer_state').toCollection().modify(row => {
        row.json = upgradeLocalizerData(row.json);
      });
    });
  }

  metaFrom(source: any) {
    return {
      clinic_user_id: source.clinic_user_id,
      eye: source.eye,
      type: source.type,
      filepath: source.filepath,
      capture_date: source.capture_date.slice(0, 10),
      sha1: source.sha1,
      metadata: source.metadata,
      sub_images: source.sub_images,
      timestamp: source.timestamp,
    };
  }

  metaWhereUser() {
    return this.clinic_user_retinal_images
      .where("clinic_user_id")
      .equals(this.cachedUser);
  }

  pngWhereUser() {
    return this.filepath_to_png
      .where("clinic_user_id")
      .equals(this.cachedUser);
  }

  locStateWhereUser() {
    return this.localizer_state
      .where("clinic_user_id")
      .equals(this.cachedUser);
  }

  async getMetaFirst(imgType: string, eye: string, date: string) {
    return this.metaWhereUser()
    .filter(row => row.capture_date == date && row.eye == eye && row.type == imgType)
    .first()
  }

  async getPng(filepath: string) {
    const row = await this.filepath_to_png
      .where('filepath')
      .equals(filepath)
      .first();
    return row!.png;
  }

  async getCaptureDates() {
    return this.clinic_user_retinal_images
      .orderBy("capture_date")
      .filter((row) => row.clinic_user_id === db.cachedUser)
      .uniqueKeys();
  }

  async countEach(rows?: clinic_user_retinal_images[]) {
    const result = {
      total: 0,
    };
    result.total = await this.metaWhereUser().count();
    if (result.total < 1)
      return result;
    for (const eye of ['OD', 'OS']) {
      result[eye] = {};
      for (const t of requiredImageTypes)
        result[eye][t] = 0;
    }
    if (!rows) rows = await this.metaWhereUser().toArray();
    for (const row of rows)
      result[row.eye][row.type]++;
    return result;
  }
}

export const db = new VVP_DB();

const requiredImageTypes = [
  'FAF',
  'FAFONH',
  'OCT',
  'FAF Properties',
  'FAFONH Properties',
  'OCT Properties',
];

export const DeleteAndRecreateDB = async () => {
  await db.delete();
  await db.open();
};

export const getOCTFovealPitImages: (
  capture_date: string,
  eye: string
) => Promise<clinic_user_retinal_images[]> = async (capture_date, eye) => {
  const rows = await getOCTBscanRows(capture_date, eye);

  return rows;

  // const images = [];

  // for (const row of rows) {
  //   const { redBitmap, bscanBitmap } = await getRedAndBscanSubImages(row);

  //   images.push({ redBitmap, bscanBitmap });
  // }

  // return images;
};

export const getOCTONHFovealPitImages: (
  capture_date: string,
  eye: string
) => Promise<clinic_user_retinal_images[]> = async (capture_date, eye) => {
  const rows = await getOCTONHBscanRows(capture_date, eye);

  return rows;

  // const images = [];

  // for (const row of rows) {
  //   const { redBitmap, bscanBitmap } = await getRedAndBscanSubImages(row);

  //   images.push({ redBitmap, bscanBitmap });
  // }

  // return images;
};

export const getOCTBscanRows = async (capture_date: string, eye: string) => {
  const rows = await db.clinic_user_retinal_images
    .where("capture_date")
    .equals(capture_date)
    .and((row) => row.eye === eye && row.type === "OCT")
    .sortBy('filepath');

  // const pngRows = await db.filepath_to_png.bulkGet(
  //   rows.map((row) => row.filepath)
  // );

  // return rows.map((row, i) => {
  //   return { ...row, png: pngRows[i].png };
  // });

  return rows;
};

const getBscanFromFilepath = async (filepath: string) => {
  const row = await db.clinic_user_retinal_images
    .where("filepath")
    .equals(filepath)
    .first();

  const pngRow = await db.filepath_to_png
    .where("filepath")
    .equals(filepath)
    .first();

  return { row, pngRow };
};

export const getOCTONHBscanRows = async (capture_date: string, eye: string) => {
  const rows = await db.clinic_user_retinal_images
    .where("capture_date")
    .equals(capture_date)
    .and((row) => row.eye === eye && row.type === "OCTONH")
    .toArray();

  // const pngRows = await db.filepath_to_png.bulkGet(
  //   rows.map((row) => row.filepath)
  // );

  // return rows.map((row, i) => {
  //   return { ...row, };
  // });

  return rows;
};

export const getRedAndBscanSubImages = async (filepath: string) => {
  const row = await db.clinic_user_retinal_images
    .where("filepath")
    .equals(filepath)
    .first();
  const pngRow = await db.filepath_to_png
    .where("filepath")
    .equals(row.filepath)
    .first();

  const blob = new Blob([pngRow.png], { type: "image/png" });

  let bitmap = await createImageBitmap(blob);

  let [x, y, width, height] = [0, 0, bitmap.width, bitmap.height];

  if (row.sub_images.RED) {
    const subImage = row.sub_images.RED;
    x = subImage.x;
    y = subImage.y;
    width = subImage.width;
    height = subImage.height;

    if (subImage.width === undefined) {
      width = bitmap.width - x;
    }

    if (subImage.height === undefined) {
      height = bitmap.height - y;
    }
  }

  const redBitmap = await createImageBitmap(blob, x, y, width, height);

  [x, y, width, height] = [0, 0, bitmap.width, bitmap.height];

  if (row.sub_images.BSCAN) {
    const subImage = row.sub_images.BSCAN;
    x = subImage.x;
    y = subImage.y;
    width = subImage.width;
    height = subImage.height;

    if (subImage.width === undefined) {
      width = bitmap.width - x;
    }

    if (subImage.height === undefined) {
      height = bitmap.height - y;
    }
  }

  const bscanBitmap = await createImageBitmap(blob, x, y, width, height);

  return { filepath: row.filepath, redBitmap, bscanBitmap };
};

export const getImageData = async (filepath: string) => {
  const row = await db.clinic_user_retinal_images
    .where("filepath")
    .equals(filepath)
    .first();
  const pngRow = await db.filepath_to_png
    .where("filepath")
    .equals(filepath)
    .first();

  if (pngRow && row) {
    return { row, pngRow };
  }
};

export const getImage = async (filepath: string) => {
  const { row, pngRow } = await getImageData(filepath);

  const blob = new Blob([pngRow.png], { type: "image/png" });

  let bitmap = await createImageBitmap(blob);

  return bitmap;
};

export const getSubImage = async (filepath: string, subImageKey?: string) => {
  const { row, pngRow } = await getImageData(filepath);

  const blob = new Blob([pngRow.png], { type: "image/png" });

  let bitmap = await createImageBitmap(blob);

  let [x, y, width, height] = [0, 0, bitmap.width, bitmap.height];

  if (subImageKey && row.sub_images[subImageKey]) {
    const subImage = row.sub_images[subImageKey];
    x = subImage.x;
    y = subImage.y;
    width = subImage.width;
    height = subImage.height;

    if (subImage.width === undefined) {
      width = bitmap.width - x;
    }

    if (subImage.height === undefined) {
      height = bitmap.height - y;
    }
  }

  bitmap = await createImageBitmap(blob, x, y, width, height);

  return bitmap;
};
