import React from "react";
import "./App.css";
import "./anims.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Wizard } from "./rewrite/routes/Wizard";
import { MainContext } from "./rewrite/contexts/MainContext";
import { ImageSyncService } from "./rewrite/contexts/ImageSyncService";
import { db } from "./db";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Wizard key='images' mode='images' />,
  },
  {
    path: "/training-localizer",
    element: <Wizard key='localizer' mode='localizer' />,
  },
  {
    path: "/home-bundle",
    element: <Wizard key='home' mode='home' />,
  },
]);

const url = new URL(window.location.href);
const auth = {
  u: url.searchParams.get("u") || process.env.REACT_APP_VIVID_UN || "",
  p: url.searchParams.get("p") || process.env.REACT_APP_VIVID_HASH || "",
  patient_id: parseInt(
    url.searchParams.get("selectedPatientId") ||
      process.env.REACT_APP_VIVID_PT_ID ||
      "0"
  ),
};

const mainCtx = {
  imageSyncService: new ImageSyncService(),
  isHome: !!url.searchParams.get("home"),
  u: auth.u,
  p: auth.p,
  patient_id: auth.patient_id,
  crc_name:
    url.searchParams.get("crc_name") ||
    process.env.REACT_APP_VIVID_CRC_NAME ||
    "",
  session_uuid: crypto.randomUUID(),
};
mainCtx.imageSyncService.cachedAuth = auth;
db.cachedUser = auth.patient_id;
console.log("app init user ", db.cachedUser);

function App() {
  return (
    <div className="p-[12px] overflow-auto">
      <MainContext.Provider value={mainCtx}>
        <RouterProvider router={router} />
      </MainContext.Provider>
    </div>
  );
}


export default App;
