import React from 'react';
import { Modal } from './Modal';

interface ChoiceModalProps {
  styling?: string;
  title: string;
  choices: Choice[];
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
const ChoiceModal: React.FC<ChoiceModalProps> = ({
  styling,
  title,
  choices,
  isOpen,
  onClose,
  children,
}) => {

  return <Modal isOpen={isOpen} onClose={onClose} title={title} styling={styling} >
    {children}
    <div className="flex justify-between gap-3">
      {choices.map(renderChoiceButton)}
    </div>
  </Modal>
};

interface Choice {
  label: string;
  styling?: string;
  onClick: () => void;
}

function renderChoiceButton(choice: Choice) {
  const styling = choice.styling || '';
  return <button
    key={choice.label}
    onClick={choice.onClick}
    className={`btn grow ${styling}`}
  >
    {choice.label}
  </button>
}

export default ChoiceModal;
