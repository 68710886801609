export const imageTypes = ["OCT", "FAF", "FAFONH", "RED", "OCTONH"];

export enum FileStatus {
  Pending = "pending",
  Processing = "processing",
  Skipped = "skipped",
  Done = "done",
  Error = "error",
}

export type ImageFile = {
  file: File;
  name: string;
  // size: number;
  // file_type: string;
  type: string | null;
  eye: string | null;
  capture_date: string | null;
  study_id: string | null;
  patient_id: string | null;
  // blobURL?: string;
  checksum: string;
  // sub_images: any;
  // lastModified: number;
  // status: FileStatus;
};

export const requiredImageTypes = {
  'OCT': 97,
  'FAF': 1,
  'FAFONH': 1,
  // 'RED',
  // 'OCT Properties': 1,
  // 'FAF Properties': 1,
  // 'FAFONH Properties': 1,
};

export const imageTypeKeywords = {
  'OCT': 'OCT',
  'FAF': 'FAF-2',
  'FAFONH': 'FAF-1M',
  'OCT Properties': 'OCT Properties',
  'FAF Properties': 'FAF-2 Properties',
  'FAFONH Properties': 'FAF-1M Properties',
};

export const ImageTypeToSubImages = {
  OCT: {
    RED: {
      x: 0,
      y: 0,
      width: 496,
      height: 496,
    },
    BSCAN: {
      x: 496,
      y: 0,
      width: undefined,
      height: 496,
    },
  },
  FAF: {
    FAF: {
      x: 0,
      y: 0,
      height: 768,
      width: 768,
    },
  },
  FAFONH: {
    FAF: {
      x: 0,
      y: 0,
      width: 768,
      height: 768,
    },
  },
};

export const imageDimensions = {
  BSCAN: { w: 1024, h: 496 },
  RED: { w: 496, h: 496 },
  FAF: { w: 768, h: 768 },
};

export const octSettings = {
  enfaceAngle: 30,
  patternSize: { w: 20, h: 20 },
  totalBscans: 97,
};

// includes files in subdirectories
export async function getDataTransferFiles(itemList: DataTransferItemList): Promise<FileSystemFileEntry[]> {
  const fileEntries: FileSystemFileEntry[] = [];
  const stack: FileSystemEntry[] = [];
  for (let i = 0; i < itemList.length; i++) {
    const listEntry = itemList[i].webkitGetAsEntry();
    if (listEntry)
      stack.push(listEntry);
  }
  while (stack.length > 0) {
    let entry = stack.pop()!;
    if (entry.isFile)
      fileEntries.push(entry as FileSystemFileEntry);
    else if (entry.isDirectory)
      stack.push(...await readAllEntries((entry as FileSystemDirectoryEntry).createReader()));
  }
  return fileEntries;
}

// loop because single read may not return all
async function readAllEntries(directoryReader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> {
  const entries: FileSystemEntry[] = [];
  let readEntries = await readEntriesAsync(directoryReader);
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesAsync(directoryReader);
  }
  return entries;
}

// wrap the oldskool callback-based API in a promise
async function readEntriesAsync(directoryReader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> {
  return await new Promise((resolve, reject) => {
    directoryReader.readEntries(resolve, reject);
  });
}
