import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import useWizardStore, {
} from '../../stores/useWizardStore';
import { db } from '../../../db';
import * as common from '../../../common';
import {
  octSettings,
} from '../../../imageFiles';
import {
  Coord,
  lerpColor,
  getTDBColor,
  hexToRGB,
  rgbToHex,
  LayoutRow,
  ResultRow,
  getDirection,
  getArrayStats,
  getFirstID,
  getStrokeColor,
  SVGtoDOMMatrix,
  generateSpindle,
  checkDistance,
  LocUpdate,
} from '../../types/LayoutTypes';
import {
  LocData,
  MarginData,
  renderLoc,
  renderMargin,
  renderMark,
} from './elements';
import VisualFieldCanvas from './VisualFieldCanvas';
import TransformGroup from './TransformGroup';
import { LabelSlider } from './LabelSlider';
import { RetinalOverlay } from './RetinalOverlay';
import { CroppedImage } from './CroppedImage';
import { VesselMarker } from './VesselMarker';

interface HomeEditorProps {
  eye: 'OD' | 'OS';
  templateCSV: LayoutRow[];
  enableCyclo: boolean;
  onComplete: (isComplete: boolean, locs?: LocUpdate[]) => void;
}
export const HomeEditor = ({
  eye,
  templateCSV,
  enableCyclo,
  onComplete,
} : HomeEditorProps) : JSX.Element => {
  const localizerCSV = useWizardStore(state => state.localizerCSV);
  const localizerResult = useWizardStore(state => state.localizerResult);
  const vascularCoords = useWizardStore(state => state.vascularCoords);

  const imageProperties = useWizardStore(state => state.imageProperties);
  const imageConfig = useWizardStore(state => state.imageConfig);

  const [blend, setBlend] = useState(0.8);
  const [showData, setShowData] = useState(true);
  const [showSpindles, setShowSpindles] = useState(false);

  const [step, setStep] = useState(0);
  const [didAction, setDidAction] = useState(true);
  const prlMatrix = useRef<DOMMatrixReadOnly>();
  const setPRL = useWizardStore(state => state.setPRL);
  const setCyclo = useWizardStore(state => state.setCyclo);
  const pgEdits = useRef(new Map<number, Coord>());
  const arrayEdits = useRef(new Map<string, Coord>());

  const parentG = useRef<SVGGElement>(null);
  const [translation, setTranslation] = useState<Coord>({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [didEditArrays, setDidEditArrays] = useState(false);
  const [marks, setMarks] = useState<number[]>([]);

  const [bscanIndex, setBscanIndex] = useState(0);
  const bscanLine = useRef<SVGLineElement>(null);
  const bscanActive = useRef(false);
  const [redX, setRedX] = useState(-15);

  const animDiv = useRef<HTMLDivElement>(null);

  // init layout
  const {
    locData,
    homeLocsByGroup,
    marginByGroup,
  } = useMemo(() => {
    const layoutByID = new Map<number, LayoutRow>();
    const resultByID = new Map<number, ResultRow>();
    const idsByGroup = new Map<string, number[]>();
    // const localizerArrayByGroup = new Map<string, Coord[]>();
    const marginByGroup = new Map<string, MarginData>();
    const homeLocsByGroup = new Map<string, LocData[]>();

    const lr = eye == 'OD' ? 'right' : 'left';
    const eyeLower = eye.toLowerCase();
    const myCSV = localizerCSV.filter(r => r.stimulus_eye == lr);
    const myResult = localizerResult.filter(r => r.stim_eye == eyeLower);
    for (const r of myCSV) {
      layoutByID.set(r.stimulus_location_id, r);
      if (!idsByGroup.has(r.array_id))
        idsByGroup.set(r.array_id, []);
      idsByGroup.get(r.array_id)!.push(r.stimulus_location_id);
    }
    for (const r of myResult)
      resultByID.set(r.locID, r);

    const locData: LocData[] = myCSV
      .map(r => {
        const resRow = resultByID.get(r.stimulus_location_id);
        const colors = resRow
          ? getColors(resRow.metric, resRow.metric_name)
          : { fill: 'transparent', stroke: '#f00' };
        return {
          id: r.stimulus_location_id,
          arrayID: r.array_id,
          diameter: r.stimulus_diameter_deg,
          fill: colors.fill,
          stroke: colors.stroke,
          x: resRow ? resRow.x_deg : r.stimulus_x_deg,
          y: resRow ? resRow.y_deg : r.stimulus_y_deg,
      }});
    const myGroups = [...idsByGroup.keys()].filter(k => homeGroups.includes(k));
    for (const grp of myGroups) {
      const [type, dir] = grp.split('-');
      const ids = idsByGroup.get(grp)!;
      const homies = locData.filter(l => ids.includes(l.id)).map(l => uncoloredLocData(l));
      if (didEditArrays && arrayEdits.current.has(grp)) {
        const edit = arrayEdits.current.get(grp)!;
        for (const h of homies) {
          h.x += edit.x;
          h.y += edit.y;
        }
      }
      const stats = getArrayStats(homies);
      const vec = getDirection(dir, eye);
      if (type == 'BS') {
        if (dir == 'C' || dir == 'T' || dir == 'N')
          homeLocsByGroup.set(grp, homies);
        if (dir != 'C')
          marginByGroup.set(grp, { arrayID: grp, vertical: vec.x != 0, stroke: getStrokeColor(type), ...stats.center });
      }
      else if (type == 'GA') {
        const spindle = generateSpindle(vec, stats.center, stats.interval);
        let firstID = getFirstID(ids);
        const crem = firstID < 0 ? -1 : 1;
        homeLocsByGroup.set(grp, spindle.map((s, i) => ({
          id: firstID + i * crem,
          arrayID: grp,
          diameter: homies[0].diameter,
          ...s,
        })));
        marginByGroup.set(grp, { arrayID: grp, vertical: vec.x != 0, stroke: getStrokeColor(type), ...stats.center });
      }
      else if (type == 'HP')
        homeLocsByGroup.set(grp, homies);
    }
    // add stuff in template which was not in localizer
    const tSet = new Set(templateCSV.map(r => r.array_id));
    for (const grp of [...tSet].filter(k => !homeLocsByGroup.has(k))) {
      const rows = templateCSV.filter(r => r.array_id == grp && r.stimulus_eye == lr);
      for (const t of rows) {
        if (!homeLocsByGroup.has(t.array_id))
          homeLocsByGroup.set(t.array_id, []);
        homeLocsByGroup.get(t.array_id)!.push({
          id: t.stimulus_location_id,
          arrayID: t.array_id,
          diameter: t.stimulus_diameter_deg,
          x: t.stimulus_x_deg,
          y: t.stimulus_y_deg,
        });
      }
    }
    for (const h of [...homeLocsByGroup.values()].flat()) {
      for (const mark of vascularCoords[eye]) {
        h.isMarked = checkDistance(mark, h, h.diameter/3);
        if (h.isMarked) break;
      }
    }

    return {
      locData,
      homeLocsByGroup,
      marginByGroup,
    };
  }, [templateCSV, localizerCSV, localizerResult, vascularCoords, eye, didEditArrays]);

  const imgMemo = useMemo(() => {
    if (!imageProperties || !imageConfig[eye])
      return undefined;
    // reg was done in raw pixel space, as unfixed->fixed. so layers are scaled together, as unfixed->local
    const retinaCfg = imageConfig[eye]!;
    const scanAngle = imageProperties.OCT[eye]!.IR.scanAngle;
    const overlayScale = scanAngle / retinaCfg.RED.crop.width;
    return {
      scanAngle,
      overlayScale,
      retinaCfg,
    };
  }, [imageProperties, imageConfig, eye]);

  const handleEditPG = useCallback((id: string, value: Coord | number, ctm: DOMMatrixReadOnly) => {
    const locID = parseInt(id);
    pgEdits.current.set(locID, value as Coord);
    setDidAction(true);
  }, []);

  if (!imageConfig.OD || !imageConfig.OS)
    return <div>Waiting for imaging...</div>;

  const {
      scanAngle,
      overlayScale,
      retinaCfg,
    } = imgMemo!;

  const mySteps = enableCyclo ? stepsStudy : stepsNonStudy;
  const currentStep = mySteps[step];
  let helpText = 'You have completed the layout for this eye! You may click Next at the bottom to proceed.';
  if (step < mySteps.length) {
    helpText = currentStep.help;
  }

  const lr = eye == 'OD' ? 'right' : 'left';
  const bsc = localizerCSV.find(r => r.stimulus_eye == lr && r.array_id == 'BS-C')!;
  let manipulator: JSX.Element | undefined = undefined;
  if (currentStep?.action == 'translate') {
    const color = getStrokeColor(enableCyclo ? 'GA' : 'BS');
    const x = enableCyclo ? 0 : bsc.stimulus_x_deg;
    const y = enableCyclo ? 0 : bsc.stimulus_y_deg;
    const r = enableCyclo ? 3 : 5;
    manipulator = <TransformGroup key='manip-t' id='prl' mode='translate' onFrame={handlePRLDrag} onUse={handlePRL}>
      <circle cx={x} cy={y} r={r} fill='transparent' stroke={color} strokeWidth={strokeWidthDeg} opacity={0.6} />
    </TransformGroup>
  }
  else if (currentStep?.action == 'rotate') {
    manipulator = <g transform={`translate(${translation.x}, ${translation.y})`}>
      <TransformGroup key='manip-r' id='prl' mode='rotate' onFrame={handleCycloDrag} onUse={handleCyclo}>
        <circle cx={bsc.stimulus_x_deg} cy={bsc.stimulus_y_deg} r={5} fill='transparent' stroke={getStrokeColor('BS')} strokeWidth={strokeWidthDeg} opacity={0.6} />
      </TransformGroup>
    </g>
  }
  else if (currentStep?.action == 'arrays') {
    manipulator = <g key='edit-arrays' transform={`translate(${translation.x}, ${translation.y}) rotate(${rotation})`} strokeWidth={strokeWidthDeg}>
      {[...homeLocsByGroup.entries()].filter(([grp, locs]) => grp.startsWith('BS') || grp.startsWith('GA')).map(([grp, locs]) => {
        const arrayType = grp.split('-')[0];
        const color = getStrokeColor(arrayType);
        const stats = getArrayStats(locs);
        return <TransformGroup key={`manip-${grp}`} id={grp} mode='translate' onFrame={handleArrayDrag} onUse={handleArray}>
          <rect x={stats.bounds.minX-1} y={stats.bounds.minY-1} width={stats.w+2} height={stats.h+2} fill='transparent' strokeWidth={0} />
          {locs.map(loc => 
            <circle key={loc.id} cx={loc.x} cy={loc.y} r={loc.diameter/2} fill='transparent' stroke={color} />
          )}
          {grp != 'BS-C' && renderMargin(marginByGroup.get(grp)!)}
        </TransformGroup>
      })}
    </g>
  }

  let hideLayerControls = false;
  let dataLayerVis = showData ? 'visible' : 'collapse';
  let homeLayerVis = showSpindles ? 'visible' : 'collapse';
  let marginVis = 'visible';
  if (currentStep?.action == 'arrays') {
    hideLayerControls = true;
    dataLayerVis = 'visible';
    homeLayerVis = 'collapse';
    marginVis = 'collapse';
  }
  else if (currentStep?.action == 'mark') {
    hideLayerControls = true;
    dataLayerVis = 'collapse';
    homeLayerVis = 'visible';
  }
  if (currentStep?.action == 'pg') {
    hideLayerControls = true;
    dataLayerVis = 'collapse';
    homeLayerVis = 'visible';
  }

  const bUrl = imageConfig[eye]!.BSCAN.urls[bscanIndex];
  const bCrop = imageConfig[eye]!.BSCAN.crop;
  const bscanCt = imageConfig[eye]!.BSCAN.urls.length;

  const octSlider = (
    <div className="flex">
      <label htmlFor='slider-oct' className='text-right text-xs w-16 mr-1'>
        {`${bscanIndex+1} of ${bscanCt}`}
      </label>
      <input
        id='slider-oct'
        type="range"
        max={bscanCt-1}
        value={bscanIndex}
        onChange={e => handleBscanSlider(parseInt(e.target.value))}
        className="flex-1"
      />
    </div>
  );
  function handleBscan_MouseDown(e: React.MouseEvent<SVGGElement>) {
    bscanActive.current = true;
    window.addEventListener('mouseup', handleMouseUp);
  }
  function handleBscan_MouseMove(e: React.MouseEvent<SVGGElement>) {
    if (!bscanActive.current)
      return;
    const ox = e.nativeEvent.offsetX;
    bscanLine.current!.setAttribute('x1', ox.toString());
    bscanLine.current!.setAttribute('x2', ox.toString());
    const g = e.currentTarget as SVGGElement;
    const p = SVGtoDOMMatrix(g.getCTM()!.inverse()).transformPoint({ x: ox, y: 0 });
    const x = p.x / bCrop.width;
    const marginDeg = (octSettings.enfaceAngle - octSettings.patternSize.w) / 2;
    const enfaceDeg = common.lerp1(x, marginDeg, octSettings.enfaceAngle - marginDeg);
    setRedX(enfaceDeg);
  }
  function handleMouseUp() {
    bscanActive.current = false;
    window.removeEventListener('mouseup', handleMouseUp);
  }

  function handleBlendSlider(v: number) {
    setBlend(v);
  }

  function handleBscanSlider(v: number) {
    setBscanIndex(v);
  }

  function handlePRLDrag(id: string, v: Coord | number) {
    setTranslation(v as Coord);
  }

  function handlePRL(id: string, v: Coord | number, m: DOMMatrixReadOnly) {
    prlMatrix.current = m;
    setDidAction(true);
  }

  function handleCycloDrag(id: string, v: Coord | number) {
    setRotation(v as number);
  }

  function handleCyclo(id: string, v: Coord | number, m: DOMMatrixReadOnly) {
    prlMatrix.current = m;
    setDidAction(true);
  }

  function handleArrayDrag(id: string, v: Coord | number) {
  }

  function handleArray(id: string, v: Coord | number, m: DOMMatrixReadOnly) {
    const c = v as Coord;
    if (Math.abs(c.x) > 0.01 || Math.abs(c.y) > 0.01)
      arrayEdits.current.set(id, c);
    setDidAction(true);
  }

  function handleMarker(id: number, marked: boolean, c: Coord) {
    if (marked)
      setMarks(m => [...m, id]);
    else setMarks(m => m.filter(i => i != id));
  }

  function handleDataToggle(e) {
    setShowData(!showData);
  }

  function handleHomeToggle(e) {
    setShowSpindles(!showSpindles);
  }

  function handleEditClick() {
    setStep(step+1);
    setDidAction(false);
    if (mySteps[step+1].action == 'arrays') {
      setShowData(true);
      setShowSpindles(false);
    }
    animDiv.current!.className = 'animate-[flash-in_0.5s]';
    setTimeout(() => {
      animDiv.current!.className = '';
    }, 500);
  }

  function handleOKClick() {
    let nexti = step + 1;
    if (mySteps[step].action.startsWith('idle'))
      nexti++;
    if (mySteps[step].action == 'idleArrays')
      nexti++;
    if (mySteps[step].action == 'arrays') {
      if (arrayEdits.current.size > 0)
        setDidEditArrays(true);
      else nexti++;
    }
    setStep(nexti);
    if (nexti >= mySteps.length) {
      setShowData(false);
      setShowSpindles(true);
      setPRL(eye, translation);
      setCyclo(eye, rotation);
      const pg = applyEdits(homeLocsByGroup.get('PG')!, pgEdits.current);
      const nonEdited: LocData[] = [];
      const edited: LocData[] = [];
      for (const [k, v] of homeLocsByGroup) {
        if (k == 'PG') continue;
        if (!arrayEdits.current.has(k)) {
          nonEdited.push(...v);
          continue;
        }
        for (const loc of v) {
          const edit = arrayEdits.current.get(k)!;
          edited.push({
            ...loc,
            // x: loc.x + edit.x,
            // y: loc.y + edit.y,
          });
        }
      }
      const up = [...nonEdited, ...edited, ...pg].map(loc => ({
        id: loc.id,
        arrayID: loc.arrayID,
        x: loc.x,
        y: loc.y
      }));
      for (const u of up) {
        if (!arrayEdits.current.has(u.arrayID))
          continue;
        (u as LocUpdate).isMarked = marks.includes(u.id);
      }
      onComplete(true, up);
    }
    else if (mySteps[nexti].action == 'idleArrays') {
      setShowData(true);
      setShowSpindles(true);
    }
    else if (mySteps[nexti].action == 'pg')
      setDidAction(false);
    animDiv.current!.className = 'animate-[flash-in_0.5s]';
    setTimeout(() => {
      animDiv.current!.className = '';
    }, 500);
  }

  function handleUndoClick() {
    prlMatrix.current = new DOMMatrixReadOnly();
    pgEdits.current.clear();
    arrayEdits.current.clear();
    setDidEditArrays(false);
    setMarks([]);
    setShowData(true);
    setShowSpindles(false);
    setTranslation({ x: 0, y: 0 });
    setRotation(0);
    setStep(0);
    onComplete(false);
    animDiv.current!.className = 'animate-[flash-in_0.5s]';
    setTimeout(() => {
      animDiv.current!.className = '';
    }, 500);
  }

  const visLocs = locData.filter(l => l.arrayID != 'HP');
  let visHome = [...homeLocsByGroup.values()].flat();
  if (currentStep?.action == 'idleArrays')
    visHome = visHome.filter(l => l.arrayID.startsWith('BS') || l.arrayID.startsWith('GA'));
  const pgGroup = homeLocsByGroup.get('PG')!;
  const pgEdited = applyEdits(pgGroup, pgEdits.current);
  const staticMargins = [...marginByGroup.entries()].filter(([k, v]) => !enableCyclo || (k != 'BS-N' && k != 'BS-T')).map(([k, v]) => v);

  const markers: JSX.Element[] = [];
  const canMark = currentStep?.action == 'mark';
  for (const k of arrayEdits.current.keys()) {
    for (const h of homeLocsByGroup.get(k)!) {
      h.isMarked = marks.includes(h.id);
      markers.push(<VesselMarker key={h.id} initValue={h.isMarked} id={h.id} x={h.x} y={h.y} r={h.diameter/2} onToggle={handleMarker} />);
    }
  }

  return (
    <div className='flex gap-2'>
      <div className='flex flex-col gap-1'>
        <VisualFieldCanvas width={600} height={600} pixelsPerDegree={10}>
          <g ref={parentG} transform='scale(10)' style={{ pointerEvents: 'none' }}>
            <g key='imaging' transform={`translate(${translation.x}, ${translation.y}) rotate(${rotation})`}>
              <RetinalOverlay config={retinaCfg} scanIndex={bscanIndex} bscanX={redX} scale={overlayScale} blend={blend} />
            </g>
            <g key='loc-data' visibility={dataLayerVis} strokeWidth={strokeWidthDeg}>
              {visLocs.map(renderLoc)}
            </g>
            {manipulator}
            <g key='home-sticky' transform={`translate(${translation.x}, ${translation.y}) rotate(${rotation})`}>
              <g key={'home-pg'} fill='#399' stroke='#7dd' strokeWidth={strokeWidthDeg}>
                {currentStep?.action == 'pg' && pgGroup.map(l => renderPG(l, handleEditPG))}
              </g>
              <g key='home-locs' visibility={homeLayerVis} fill='none' stroke='#fff' strokeWidth={strokeWidthDeg}>
                {currentStep?.action != 'pg' && currentStep?.action != 'idleArrays' && pgEdited.map(renderLoc)}
                {visHome.filter(l => l.arrayID != 'PG').map(renderLoc)}
                {visHome.filter(l => l.isMarked).map(renderMark)}
                <g key='markers' fill='transparent' stroke='white' strokeWidth={strokeWidthDeg} style={{ pointerEvents: canMark ? 'auto' : 'none' }}>
                  {markers}
                </g>
              </g>
              <g key='margins' visibility={marginVis} strokeWidth={strokeWidthDeg}>
                {staticMargins.map(renderMargin)}
              </g>
            </g>
          </g>
        </VisualFieldCanvas>
        <LabelSlider id='blend' label='FAF Blend' initValue={80} onValue={handleBlendSlider} />
      </div>
      <div className='flex flex-col flex-1 gap-1'>
        <svg height={300}>
          <g transform={`scale(${300/bCrop.height})`} onMouseDown={handleBscan_MouseDown} onMouseMove={handleBscan_MouseMove}>
            <CroppedImage x={bCrop.x} y={bCrop.y} w={bCrop.width} h={bCrop.height} url={bUrl} />
          </g>
          <line ref={bscanLine} x1={0} y1={0} x2={0} y2={300} stroke='#ff0' opacity={0.5} style={{ pointerEvents: "none" }} />
        </svg>
        {octSlider}
        <div ref={animDiv}>
          <article className='my-8'>
            <p className='text-wrap'>{step+1}. {helpText}</p>
            {currentStep && <p className='text-sm italic'>{currentStep.tooltip}</p>}
            <p className='text-sm italic'>Click and drag empty areas to pan the view. Scroll the mouse wheel to zoom the view.</p>
            <p className='text-sm italic'>Click and drag the B-scan to place a vertical line onto the infrared image.</p>
          </article>
          <div className='flex flex-row gap-1'>
            {currentStep && (
            <button onClick={handleOKClick} disabled={!didAction} className="btn">{currentStep.confirmButton}</button>
            )}
            {currentStep?.editButton && (
            <button onClick={handleEditClick} className="btn bg-purple-500">{currentStep.editButton}</button>
            )}
            {(!currentStep) && (
            <button onClick={handleUndoClick} className="btn bg-teal-600">{!currentStep ? 'Undo All' : 'currentStep.undoButton'}</button>
            )}
          </div>
        </div>
        <hr />
        <div style={{visibility: hideLayerControls? 'hidden' : 'visible'}}>
          <input type='checkbox' id='show-data' checked={showData} onChange={handleDataToggle} className='mr-2' />
          <label htmlFor='show-data'>Show Localizer Data</label>
        </div>
        <div style={{visibility: hideLayerControls? 'hidden' : 'visible'}}>
          <input type='checkbox' id='show-spindles' checked={showSpindles} onChange={handleHomeToggle} className='mr-2' />
          <label htmlFor='show-spindles'>Show Home Locations</label>
        </div>
      </div>
    </div>
  );
}




const strokeWidthDeg = 0.05;

function getColors(metric: number, metricName: string) {
  let fill = '#000000';
  if (metricName == 'sensitivity_tdB') fill = getTDBColor(metric);
  else if (metricName == 'percent_seen') fill = rgbToHex(lerpColor(metric/100, [0, 0, 0], [255, 255, 255]));
  else if (metricName == 'percent_responded') fill = rgbToHex(lerpColor(metric/100, [0, 0, 0], [255, 64, 64]));
  const stroke = rgbToHex(lerpColor(0.3, hexToRGB(fill), [255, 255, 255]));
  return {
    fill,
    stroke,
  }
}

function uncoloredLocData(loc: LocData): LocData {
  const { fill, stroke, ...rest } = loc;
  return rest;
}

function renderPG(loc: LocData, onUse) {
  return <TransformGroup key={'PG '+loc.id} id={loc.id.toString()} mode='translate' onUse={onUse}>
    {renderLoc(loc)}
    <text fontSize={0.8} textAnchor='middle' dominantBaseline='middle' strokeWidth={0.02} transform={`translate(${loc.x},${loc.y+1.2})`}>PG</text>
    <rect x={loc.x-1} y={loc.y-1} width={2} height={2} fill='transparent' strokeWidth={0} />
  </TransformGroup>
}

function applyEdits(locs: LocData[], edits: Map<number, Coord>) {
  return locs?.map(l => {
    const edit = edits.get(l.id);
    if (!edit) return l;
    return {
      ...l,
      x: l.x + edit.x,
      y: l.y + edit.y,
    }
  });
}


const homeGroups = [
  'BS-C',
  'BS-T',
  'BS-N',
  'BS-S',
  'BS-I',
  'GA-T',
  'GA-N',
  'GA-S',
  'GA-I',
  'HP',
  'PG',
];

const stepsNonStudy = [
  {
    action: 'idlePRL',
    confirmButton: 'Accept BS Alignment',
    editButton: 'Edit PRL',
    help: 'Examine the blindspot data for alignment with the retinal images. Edit if needed.',
    tooltip: '',
  },
  {
    action: 'translate',
    confirmButton: 'Confirm PRL Adjustment',
    // undoButton: 'Reset PRL',
    help: 'Move the imaging layer to balance the sensitivity data between opposing BS arrays. Each margin line should mark a transition from low to higher sensitivity.',
    tooltip: 'Click and drag the blindspot region to move the imaging layer.',
  },
  {
    action: 'pg',
    confirmButton: 'Confirm PG Locations',
    // undoButton: 'Reset PG',
    help: 'Move the peripheral (PG) locations away from blood vessels if needed.',
    tooltip: 'Click and drag a PG location to move it.',
  },
];

const stepsStudy = [
  {
    action: 'idlePRL',
    confirmButton: 'Accept GA Alignment',
    editButton: 'Edit PRL',
    help: 'Examine the GA data for alignment with the retinal images. Edit if needed.',
    tooltip: '',
  },
  {
    action: 'translate',
    confirmButton: 'Confirm PRL Adjustment',
    // undoButton: 'Reset PRL',
    help: 'Move the imaging layer to balance the sensitivity data between opposing GA arrays. Each margin line should mark a transition from low to higher sensitivity.',
    tooltip: 'Click and drag the foveal region to move the imaging layer.',
  },
  {
    action: 'idleCyclo',
    confirmButton: 'Accept BS Alignment',
    editButton: 'Edit Cyclo',
    // undoButton: 'Undo PRL',
    help: 'Examine the blindspot data for alignment with the retinal images. Edit if needed.',
    tooltip: '',
  },
  {
    action: 'rotate',
    confirmButton: 'Confirm Cyclo Adjustment',
    // undoButton: 'Reset Cyclo',
    help: 'Rotate the imaging layer to balance the sensitivity data between opposing BS arrays. Each margin line should mark a transition from low to higher sensitivity.',
    tooltip: 'Click and drag the blindspot region to rotate the imaging layer.',
  },
  {
    action: 'idleArrays',
    confirmButton: 'Accept Array Placement',
    editButton: 'Edit Individual Arrays',
    help: 'Determine whether each array (home locations - white circles) should remain placed as-is. Edit if needed.',
    tooltip: '',
  },
  {
    action: 'arrays',
    confirmButton: 'Confirm Array Adjustments',
    help: 'Move any BS or GA array to place stimuli upon a different part of the retina than previously intended. Move all desired arrays before continuing.',
    tooltip: 'Click and drag any array to move it.',
  },
  {
    action: 'mark',
    confirmButton: 'Confirm Markings',
    help: 'Mark any stimuli locations which are covered more than 50% by a blood vessel.',
    tooltip: 'Click on any stimulus location to toggle markings.',
  },
  {
    action: 'pg',
    confirmButton: 'Confirm PG Locations',
    // undoButton: 'Reset PG',
    help: 'Move the peripheral (PG) locations away from blood vessels if needed.',
    tooltip: 'Click and drag a PG location to move it.',
  },
];
