import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useCallback } from "react";
import ReactDOM from "react-dom";

interface ModalProps {
  styling?: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  styling = 'w-[400px]',
  title,
  isOpen,
  onClose,
  children,
}) => {

  return ReactDOM.createPortal(
    <div className={`${isOpen ? "" : "delay-300 invisible"}`}>
      <div
        className={`${
          isOpen ? "opacity-100" : "opacity-0"
        } fixed inset-0 grid z-50 place-items-center w-screen h-screen backdrop-brightness-50 transition-opacity`}
      >
        <div className={`bg-white p-4 rounded-xl space-y-2 ${styling}`}>
          <div className="flex text-gray-500 items-center justify-between">
            <h1 className="text-lg font-medium text-gray-500">{title}</h1>
            <button className="text-2xl cursor-pointer" onClick={onClose}>
              <Icon icon="ion:close" />
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export function useModal() {
  const [isOpen, setIsOpen] = React.useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return { isOpen, open, close };
}

export const TestModal: React.FC = () => {
  const { isOpen, open, close } = useModal();

  return (
    <div>
      <button onClick={open}>Open Modal</button>
      <Modal isOpen={isOpen} onClose={close} title="Test">
        <p>This is the modal content.</p>
        <div className="flex items-center justify-between">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={close}
          >
            Ok
          </button>
          <button
            className="border-blue-500 border-4 text-black px-4 py-2 rounded-md"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};
