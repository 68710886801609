import React, {
  useState,
  useEffect,
  useRef,
  // useReducer,
  // useMemo,
  // useContext,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';

interface CroppedImageProps {
  x: number;
  y: number;
  w: number;
  h: number;
  url?: string;
  blob?: Blob;
}
export const CroppedImage = ({
  x,
  y,
  w,
  h,
  url,
  blob,
}: CroppedImageProps): JSX.Element => {

  const myURL = url ?? URL.createObjectURL(blob!);

  return (
    <svg width={w ?? 9000} height={h ?? 9000}>
      <image
        href={myURL}
        crossOrigin='anonymous'
        x={-x}
        y={-y}
      />
    </svg>
  );
};
