import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import useWizardStore, {
} from "../stores/useWizardStore";
import { useModal } from './Modal';
import ChoiceModal from './ChoiceModal';

export function SyncImages({
  isBusy,
  progress,
  onMount,
  onClickImport,
  onClickDelete,
}): JSX.Element {
  const dbStats = useWizardStore(state => state.dbStats);
  const hasRequiredImages = useWizardStore(state => state.hasRequiredImages);
  const selectedCaptureDate = useWizardStore(state => state.selectedCaptureDate);

  const deleteModal = useModal();

  useEffect(() => {
    onMount();
  }, []);

  function handleExit() {
    window.parent.postMessage('closeModal', '*');
  }

  if (isBusy || !dbStats)
    return contain(<>
      <div className='text-gray-500 text-sm font-semibold'>{isBusy ? 'Synchronizing with server' : 'Loading'}</div>
      <div className='dotpulse-container'>
        <div className='dotpulse' />
      </div>
      {progress < 1 && renderProgress(progress)}
    </>);

  let statsGrid = <div>No images</div>;
  if (dbStats.total > 0) {
    const transposed = new Map<string, {OD: number, OS: number}>();
    for (const eye of ['OD', 'OS']) {
      const counts = dbStats[eye];
      for (const [k, v] of Object.entries(counts)) {
        if (!transposed.has(k))
          transposed.set(k, {OD: 0, OS: 0});
        transposed.get(k)![eye] = v;
      }
    }

    statsGrid = <div className='grid grid-cols-[auto_auto_auto] gap-2 p-2 text-right bg-gray-100 rounded-md'>
      <div className='text-sm italic px-1 border-b-2'>Image file type</div><div className='col-span-2 text-sm italic px-1 border-b-2'>Number of files</div>
      <div className='col-start-2 font-bold'>OD</div><div className='font-bold'>OS</div>
      {[...transposed.entries()].filter(([k]) => !k.includes('Properties')).map(([k, v]) => (<React.Fragment key={k}><div className='font-medium'>{imgTypeLabels[k]}</div><div>{v.OD}</div><div>{v.OS}</div></React.Fragment>))}
    </div>
  }

  const canImport = dbStats.total < 1;
  const canDelete = dbStats.total > 0;

  return contain(<>
    {dbStats.total > 0 && <div>{`${dbStats.total} images dated: ${selectedCaptureDate}`}</div>}
    {statsGrid}
    <div className='flex gap-4'>
      <button
        className='btn'
        onClick={onClickImport}
        disabled={!canImport}
      >
        Import Images
      </button>
      <button
        className='btn bg-red-500'
        onClick={deleteModal.open}
        disabled={!canDelete}
      >
        Delete Images
      </button>
    </div>
    {hasRequiredImages && <div>Images are ready for use.</div>}
    <button onClick={handleExit} className='btn'>Return to Dashboard</button>
    <ChoiceModal
      title=''
      isOpen={deleteModal.isOpen}
      onClose={deleteModal.close}
      choices={[
        { label: 'Yes', styling: 'bg-red-500', onClick: () => { deleteModal.close(); onClickDelete(); } },
        { label: 'No', onClick: deleteModal.close },
      ]}
      >
      <p>Are you sure you want to delete this image set? You will need to import new images in order to design tests for this user.</p>
    </ChoiceModal>
  </>);
}




function contain(children: React.ReactNode) {
  return <div className='flex flex-col justify-center items-center gap-2 bg-gray-200 p-2 rounded-md min-h-[6rem]'>
    {children}
  </div>
}

function renderProgress(p: number) {
  const pct = Math.trunc(100 * p);
  return <div className='relative w-1/2 h-2'>
    <div className='z-0 absolute w-full h-full rounded-md bg-blue-200' />
    <div className='z-[1] absolute w-full h-full rounded-md bg-blue-400' style={{clipPath: `rect(auto ${pct}% auto auto)`}} />
  </div>
}

const imgTypeLabels = {
  FAF: 'FAF Field 2',
  FAFONH: 'FAF Field 1M',
  OCT: 'OCT + IR',
};
