import React, {

} from 'react';

export const FinishPage = ({
  layout,
  isBusy,
  onConfirm,
  children,
}): JSX.Element => {

  const text = layout == 'Localizer'
    ? 'Click below to save the Training and Localizer layouts and exit this interface.'
    : 'Click below to save the Home layout and exit this interface.';
  return (
    <div className='flex flex-col gap-2 justify-evenly items-center bg-gray-100 p-2 rounded-md min-h-[12rem]'>
      {isBusy && (<>
        <div className="text-gray-500 text-sm font-semibold">Loading</div>
        <div className="dotpulse-container">
          <div className="dotpulse" />
        </div>
      </>)}
      {!isBusy && (
        <div>{text}</div>
      )}
      <button onClick={onConfirm} disabled={isBusy} className='btn'>Save & Exit</button>
      {children}
    </div>
  );
};
