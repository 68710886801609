import { create } from 'zustand';
import { ImageFile } from '../../imageFiles';

interface ImageFilesState {
  imageFiles: ImageFile[];
}

interface ImageFilesActions {
  reset: () => void;
  addImageFile: (imageFile: ImageFile) => void;
  addImageFiles: (imageFiles: ImageFile[]) => void;
  updateImageFile: (fields: {[key:string]: any}) => void;
  removeImageFile: (imageFile: ImageFile) => void;
  clearImageFiles: () => void;
}

const initialState: ImageFilesState = {
  imageFiles: [],
};

const useImageFilesStore = create<ImageFilesState & ImageFilesActions>((set, get) => ({
  ...initialState,
  reset: () => set(initialState),

  addImageFile: (imageFile: ImageFile) =>
    set((state) => ({ imageFiles: [...state.imageFiles, imageFile] })),
  addImageFiles: (imageFiles: ImageFile[]) =>
    set((state) => ({ imageFiles: [...state.imageFiles, ...imageFiles] })),
  updateImageFile: (fields: {[key:string]: any}) =>
    set((state) => {
      const target = state.imageFiles.find(f => f.name === fields.name)!;
      for (const [k, v] of Object.entries(fields))
        target[k] = v;
      return { imageFiles: [...state.imageFiles] };
    }),
  removeImageFile: (imageFile: ImageFile) =>
    set((state) => ({
      imageFiles: state.imageFiles.filter(
        (file) => file.name !== imageFile.name
      ),
    })),
  clearImageFiles: () => set({ imageFiles: [] }),
}));

export default useImageFilesStore;
