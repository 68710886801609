import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import useWizardStore from '../stores/useWizardStore';

interface StudyEyeSelectorProps {
  onSelect?: (eye: string) => void;
}
export const StudyEyeSelector = ({
  onSelect,
}: StudyEyeSelectorProps) : JSX.Element => {
  const studyEye = useWizardStore(state => state.studyEye);
  const setStudyEye = useWizardStore(state => state.setStudyEye);

  const handleSelect_Change = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    onSelect?.(e.target.value);
    if (e.target.value == 'OD' || e.target.value == 'OS')
      setStudyEye(e.target.value);
  }, [onSelect, setStudyEye]);

  const selected = studyEye ?? '';

  return (
    <select value={selected} onChange={handleSelect_Change} className='bg-blue-100 p-2 rounded-md'>
      <option value='' className='text-gray-600'>Select...</option>
      <option value='OD'>OD</option>
      <option value='OS'>OS</option>
    </select>
  );
};
