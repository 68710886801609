import * as common from '../../common';
import { WebTools } from '../../WebTools';
import { WorkerPool } from '../workerPool';
import { db, clinic_user_retinal_images, filepath_to_png } from '../../db';

class ImageSyncService
{
  cachedAuth: VividAuthWithPatient;

  private static readonly VIVID_URL = 'https://www.seevividly.com/api_localizer/';
  private static readonly VIVIDAPI_SYNC = 'sync_retinal_images';
  private static readonly VIVIDAPI_UPLOAD = 'upload_retinal_images';
  private static readonly VIVIDAPI_DELETE = 'delete_retinal_images';
  private static readonly LAMBDA_URL = 'https://43vb5jhsi6ewen44757wphw5ym0xetia.lambda-url.us-east-1.on.aws/';
  private _subdirs: ImageTree<string>;
  private _presignedGet: S3PresignedGetInfo;
  private _presignedGetTime: number;
  private _presignedUpload: S3PresignedUploadInfo;
  private _presignedUploadTime: number;

  private _syncedRows: clinic_user_retinal_images[];
  private _collator = new Intl.Collator('en');

  get subdirs() { return this._subdirs; }

  async syncVivid(timeout: number, cancel: AbortSignal) : Promise<any>
  {
    const r = await this.queryVivid(timeout, cancel);
    const myRows = await db.metaWhereUser()
      .sortBy('filepath');
    console.log(`syncing from ${myRows.length} local rows`);
    const myMap = common.buildMap(myRows, r => r.filepath);
    const myOld = myRows.length < 1 ? '0' : common.selectMin(myRows, v => v.timestamp).timestamp;
    const svOld = r.rows.length < 1 ? '0' : common.selectMin(r.rows, v => v.timestamp).timestamp;
    const willClear = (r.delete && myRows.some(row => row.timestamp <= r.delete))
      || (!r.delete && r.rows.length < 1)
      || (!r.delete && r.rows.length > 0 && myRows.length > 0 && svOld > myOld);

    if (willClear && myRows.length > 0) {
      if (r.delete && myRows.some(row => row.timestamp > r.delete))
        console.warn('Unexpected: local rows to delete, but some others are newer');
      const myKeys = await db.metaWhereUser().primaryKeys();
      await db.clinic_user_retinal_images.bulkDelete(myKeys);
      await db.filepath_to_png.bulkDelete(myKeys);
      myMap.clear();
      console.log(`deleted ${myKeys.length} local rows`);
    }
    const toPut: clinic_user_retinal_images[] = [];
    const staleChecksums: string[] = [];
    for (const row of r.rows) {
      const mine = myMap.get(row.filepath);
      if (!mine || mine.timestamp < row.timestamp)
        toPut.push(row);
      if (mine && mine.sha1 !== row.sha1)
        staleChecksums.push(row.filepath);
    }
    if (staleChecksums.length > 0) {
      await db.filepath_to_png.bulkDelete(staleChecksums);
      console.log(`deleted ${staleChecksums.length} stale rows`);
    }
    if (toPut.length > 0)
      await db.clinic_user_retinal_images.bulkPut(toPut);
    console.log(`put ${toPut.length} synced rows`);
    return {
      all: r.rows,
      fresh: [...staleChecksums, ...toPut.map(r => r.filepath)],
    };
  }

  async queryVivid(timeout: number, cancel: AbortSignal) : Promise<VividSyncResponse>
  {
    const body = {
      ...this.cachedAuth,
    };
    // @ts-ignore
    const signal = AbortSignal.any([cancel, AbortSignal.timeout(timeout)]);
    const r = await WebTools.apiPost<VividSyncResponse>(ImageSyncService.VIVID_URL + ImageSyncService.VIVIDAPI_SYNC, body, signal);
    r.rows = r.rows.map(r => db.metaFrom(r));
    r.rows.sort((a, b) => this._collator.compare(a.filepath, b.filepath));
    return r;
  }

  async uploadVivid(filenames: string[], timeout: number, cancel: AbortSignal)
  {
    const body = {
      ...this.cachedAuth,
      files: []
    };
    const myRows = await db.metaWhereUser()
      .sortBy('filepath');
    const uploadRows = myRows.filter(row => filenames.includes(row.filepath));
    console.log(`uploading ${uploadRows.length} local rows`);
    body.files = uploadRows;
    // @ts-ignore
    const signal = AbortSignal.any([cancel, AbortSignal.timeout(timeout)]);
    const results = await WebTools.apiPost<VividUploadResponse>(ImageSyncService.VIVID_URL + ImageSyncService.VIVIDAPI_UPLOAD, body, signal);
    console.log(results);
    console.log(`Upload resulted in ${results.write.length} written, ${results.skip.length} skipped, ${results.error.length} errors`);
    return results;
  }

  async deleteVivid(timeout: number, cancel: AbortSignal)
  {
    const body = {
      ...this.cachedAuth,
      time: Date.now() / 1000
    };
    // @ts-ignore
    const signal = AbortSignal.any([cancel, AbortSignal.timeout(timeout)]);
    const result = await WebTools.apiPost<any>(ImageSyncService.VIVID_URL + ImageSyncService.VIVIDAPI_DELETE, body, signal);
    console.log('Deleted images from seevividly');
    return result;
  }

  async requestSubdirs() : Promise<ImageTree<string>>
  {
    // if (this._subdirs !== undefined)
    //   return this._subdirs;
    this._subdirs = (await WebTools.apiPost<SubdirResponse>(ImageSyncService.LAMBDA_URL, { method: 'subdir' })).subdir_info;
    console.log('cached subdirs: ', this._subdirs);
    return this._subdirs;
  }

  async requestPresignedGet(timeout: number, cancel: AbortSignal) : Promise<S3PresignedGetInfo>
  {
    // if (this._presignedGetTime && Date.now() - this._presignedGetTime < 3000)
    //   return this._presignedGet;
    const body = {
      ...this.cachedAuth,
      method: 'sync',
      files: {}
    };
    const myRows = await db.metaWhereUser()
      .sortBy('filepath');
    // build files arg like ImageTree of filename:checksum
    for (const row of myRows) {
      const pngExists = await db.filepath_to_png
        .where('filepath')
        .equals(row.filepath)
        .count() > 0;
      if (!pngExists)
        continue;
      body.files[row.filepath.split('/').pop()] = row.sha1;
    }
    // @ts-ignore
    const signal = AbortSignal.any([cancel, AbortSignal.timeout(timeout)]);
    this._presignedGet = await WebTools.apiPost<S3PresignedGetInfo>(ImageSyncService.LAMBDA_URL, body, signal);
    this._presignedGetTime = Date.now();
    return this._presignedGet;
  }

  async requestPresignedUpload(timeout: number, cancel: AbortSignal) : Promise<S3PresignedUploadInfo>
  {
    if (this._presignedUploadTime && Date.now() - this._presignedUploadTime < 3600000)
      return this._presignedUpload;
    const body = {
      ...this.cachedAuth,
      method: 'upload'
    };
    // @ts-ignore
    const signal = AbortSignal.any([cancel, AbortSignal.timeout(timeout)]);
    this._presignedUpload = (await WebTools.apiPost<PresignedUploadResponse>(ImageSyncService.LAMBDA_URL, body, signal)).presigned_info;
    this._presignedUploadTime = Date.now();
    return this._presignedUpload;
  }

  async downloadS3(presignedInfo: S3PresignedGetInfo, timeout: number, onEach: () => void) : Promise<any[]>
  {
    const tasks = [];
    for (let [filepath, url] of Object.entries(presignedInfo)) {
      if (!filepath.startsWith(this.cachedAuth.patient_id.toString()))
        filepath = this.cachedAuth.patient_id + '/' + filepath;
      tasks.push({
        method: 'downloadS3',
        args: {
          patientID: this.cachedAuth.patient_id,
          path: filepath,
          url: url,
          timeout: timeout,
        },
      });
    }
    const pool = new WorkerPool('imageSync', 2);
    const downloads = new Map<number, Promise<any>>();
    const failed = [];
    const onComplete = (v, i) => {
      console.log(`downloaded ${v.bytes.byteLength} bytes ${tasks[i].args.path}`);
      downloads.delete(i);
      onEach();
    };
    const onError = (e, i) => {
      console.warn(e.name, e.message);
      failed.push({
        filepath: tasks[i].args.path,
        errorName: e.name,
      });
    };
    for (let i = 0; i < tasks.length; i++) {
      const p = pool.addTask(tasks[i])
        .then(v => onComplete(v, i))
        .catch(e => onError(e, i));
        downloads.set(i, p);
    }
    await Promise.all([...downloads.values()]);
    return failed;
  }

  async uploadS3(presignedInfo: S3PresignedUploadInfo, paths: string[], timeout: number, onEach: () => void) : Promise<any[]>
  {
    await this.requestSubdirs();
    const metaRows = await db.clinic_user_retinal_images
      .where('filepath').anyOf(paths)
      .sortBy('filepath');
    const pngCt = await db.filepath_to_png
      .where('filepath').anyOf(paths)
      .count();
    if (metaRows.length !== paths.length || pngCt !== paths.length)
      throw new Error('File list inconsistent with database');

    const tasks = metaRows.map(row => {
      const baseType = row.type.replace(' Properties', '');
      const subdir = this._subdirs[baseType][row.eye];
      return {
        method: 'uploadS3',
        args: {
          path: row.filepath,
          subdir: subdir,
          pre: presignedInfo,
          timeout: timeout,
        },
      };
    });
    const pool = new WorkerPool('imageSync', 2);
    const uploads = new Map<number, Promise<any>>();
    const failed = [];
    const onComplete = (v, i) => {
      uploads.delete(i);
      onEach();
    };
    const onError = (e, i) => {
      console.warn(e.name, e.message);
      failed.push({
        filepath: tasks[i].args.path,
        errorName: e.name,
      });
    };
    for (let i = 0; i < tasks.length; i++) {
      const p = pool.addTask(tasks[i])
        .then(v => onComplete(v, i))
        .catch(e => onError(e, i));
      uploads.set(i, p);
    }
    await Promise.all([...uploads.values()]);
    return failed;
  }
  
  async deleteS3(timeout: number, cancel: AbortSignal) : Promise<any>
  {
    const body = {
      ...this.cachedAuth,
      method: 'delete'
    };
    // @ts-ignore
    const signal = AbortSignal.any([cancel, AbortSignal.timeout(timeout)]);
    const r = await WebTools.apiPost<S3DeleteResponse>(ImageSyncService.LAMBDA_URL, body, signal);
    if (r.errors.length > 0)
      throw new Error('Failed to delete from S3');
    console.log('Deleted images from S3');
    return r;
  }
}


function buildAWSUploadForm(presignedInfo: S3PresignedUploadInfo,
  filename: string,
  subdir: string,
  sha1: string,
  png: ArrayBuffer)
{
  const form = new FormData();
  for (const k in presignedInfo.fields) {
    if (k === 'key') continue;
    form.append(k, presignedInfo.fields[k]);
  }
  form.append('Content-Type', 'image/png');
  form.append('x-amz-meta-sha1', sha1);
  form.append('key', presignedInfo.fields.key.replace('${filename}', subdir + '/' + filename + '.png'));
  form.append('file', new Blob([png], { type: 'image/png' }), filename);
  return form;
}


interface ImageBasicProps {
  type: string;
  eye: string;
  filepath: string;
}

type ImageTree<T> = {
  [key: string]: {
    OD: T;
    OS: T;
  }
}

type VividSyncResponse = {
  delete: string | null;
  rows: clinic_user_retinal_images[];
}

type VividUploadResponse = {
  write: string[];
  skip: string[];
  error: string[];
}

type VividDeleteResponse = {
  paths: string[];
}

type SubdirResponse = {
  subdir_info: ImageTree<string>;
}

type S3PresignedGetInfo = {
  [key:string]: string
};

type S3PresignedUploadInfo = {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    'x-amz-security-token': string;
    policy: string;
    signature: string;
  }
};

type PresignedUploadResponse = {
  presigned_info: S3PresignedUploadInfo;
}

type S3DeleteResponse = {
  errors: any[];
}

interface VividAuth {
  u: string;
  p: string;
}

interface VividAuthWithPatient extends VividAuth {
  patient_id: number;
}

export {
  ImageSyncService,
  type ImageBasicProps,
  type ImageTree,
  type S3PresignedGetInfo,
  type S3PresignedUploadInfo,
  buildAWSUploadForm,
};
