import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useNavigate } from 'react-router';
import useWizardStore from '../stores/useWizardStore';
import { StudyEyeSelector } from './StudyEyeSelector';

export const StartPage = ({
  onStart,
}): JSX.Element => {
  const navigate = useNavigate();
  const hasRequiredImages = useWizardStore(state => state.hasRequiredImages);
  const selectedCaptureDate = useWizardStore(state => state.selectedCaptureDate);
  const studyEye = useWizardStore(state => state.studyEye);

  const handleDesign = useCallback(() => {
    onStart();
  }, []);

  const handleManageImages = useCallback(() => {
    console.log('nav');
    navigate('/', { replace: true });
  }, []);

  if (!hasRequiredImages)
    return contain(<>
      <div>Images for this user are not ready on this device. Go to Image Management to synchronize files or import new files.</div>
      <button onClick={handleManageImages} className='btn'>Manage Images</button>
    </>);

  return contain(<>
    <div>{`Images dated: ${selectedCaptureDate}`}</div>
    <div className='flex flex-row items-center gap-2'>
      Study Eye: 
      <StudyEyeSelector />
    </div>
    <button onClick={handleDesign} disabled={!studyEye} className='btn'>Start Designing</button>
  </>);
}


function contain(children: React.ReactNode): JSX.Element {
  return (<div className='flex flex-col gap-2 justify-evenly items-center bg-gray-100 p-2 rounded-md min-h-[12rem]'>
    {children}
  </div>);
}
