import React, {
  useState,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router';

const UploadProgress = ({
  progress,
  canGotoB,
}): JSX.Element => {
  const navigate = useNavigate();

  function handleExit() {
    window.parent.postMessage('close', '*');
  }

  function handleGotoA() {
    navigate('/training-localizer', { replace: true });
  }

  function handleGotoB() {
    navigate('/home', { replace: true });
  }

  if (progress <= 0)
    return <div>Loading...</div>;

  const pct = (100*progress).toFixed();
  const syncText = progress < 1 ? 'Synchronizing with remote server...' : 'Synchronization complete.';

  return <div className='flex flex-col items-center p-4 gap-2 rounded-md bg-gray-200'>
    <div className='relative w-full h-6'>
      <div className='z-0 absolute w-full h-full rounded-md bg-blue-400' />
      <div className='z-[1] absolute w-full h-full rounded-md bg-blue-700' style={{clipPath: `rect(auto ${pct}% auto auto)`}} />
      <div className='z-[2] absolute right-1/2 text-white'>{pct}</div>
      <div className='z-[2] absolute left-1/2 text-white'>%</div>
    </div>
    <div className='text-center'>{syncText}</div>
    {progress >= 1 && <>
      <div className='text-center'>Images are now ready for use.</div>
      <div className='flex flex-row gap-4'>
        <button className='btn' onClick={handleGotoA}>Design Training & Localizer Tests</button>
        <button className='btn' onClick={handleGotoB} disabled={!canGotoB}>Design Home Test</button>
        <button className='btn min-w-[6rem]' onClick={handleExit}>Return to Dashboard</button>
      </div>
    </>}
  </div>
};

export default UploadProgress;
