import React, { useCallback, useEffect } from "react";
import { UploadDropArea } from "./UploadDropArea";
import useImageFilesStore from "../stores/ImageFilesStore";
import { LowresImageRenderer } from "./ImageRenderer";
import { useVirtualizer } from "@tanstack/react-virtual";
import { requiredImageTypes } from "../../imageFiles";
import { Tab, Tabs } from "./Tabs";

interface ImageScrollProps {
  type: string;
}

const ImageScroll: React.FC<ImageScrollProps> = ({ type }) => {



  const imageFiles = useImageFilesStore(state => state.imageFiles);

  const filteredImageFiles = type.includes("Properties") ? imageFiles.filter((i) => i.type?.includes("Properties")) : imageFiles.filter((i) => i.type === type);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = React.useState(0);



  const columnRefs = React.useRef<Record<string, HTMLDivElement | null>>({});

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: filteredImageFiles.length,
    getScrollElement: () => containerRef.current,
    estimateSize: (i) => columnRefs.current[i]?.clientWidth || 300,
    overscan: 5,
  })


  // const Column = ({ index, style }) => (
  //   <div key={filteredImageFiles[index].name} style={style} ref={(el) => columnRefs.current[index] = el} className="flex flex-col items-center">
  //     <LowresImageRenderer
  //       key={filteredImageFiles[index].name}
  //       height={64}
  //       file={filteredImageFiles[index].file}
  //     />
  //     <div className="text-[0.6rem]">{filteredImageFiles[index].name}</div>
  //   </div>
  // );

  return (
    <div className="w-full">
      <div
        style={{
          height: `96px`,
          overflow: 'auto',
        }}
        ref={containerRef}
      >
        <div
          style={{
            width: `${columnVirtualizer.getTotalSize()}px`,
            height: '100%',
            position: 'relative',
          }}
        >
          {columnVirtualizer.getVirtualItems().map((virtualColumn) => (
            <div key={virtualColumn.index} ref={(el) => columnRefs.current[virtualColumn.index] = el} style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: `${virtualColumn.size}px`,
              transform: `translateX(${virtualColumn.start}px)`,
            }} className="flex flex-col items-center">
              <LowresImageRenderer
                key={filteredImageFiles[virtualColumn.index].name}
                height={64}
                file={filteredImageFiles[virtualColumn.index].file}
              />
              <div className="text-[0.6rem]">{filteredImageFiles[virtualColumn.index].name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const UploadImagesWithPreview = ({
  onDropReady,
}) => {

  const imageFiles = useImageFilesStore(state => state.imageFiles);

  const getNumImagesPerEye = useCallback((type: string) => {
    if (type.includes("Properties")) {
      return {
        OD: `OD: ${imageFiles.filter((i) => i.type?.includes("Properties") && i.eye === "OD").length}`,
        OS: `OS: ${imageFiles.filter((i) => i.type?.includes("Properties") && i.eye === "OS").length}`,
      };
    }
    return {
      OD: `OD: ${imageFiles.filter((i) => i.type === type && i.eye === "OD").length}`,
      OS: `OS: ${imageFiles.filter((i) => i.type === type && i.eye === "OS").length}`,
    };
  }, [imageFiles]);

  return (
    <div className="space-y-2 w-full">
      <UploadDropArea onReady={onDropReady} />
      <Tabs>
        <Tab label={`OCT`} secondaryLabels={[getNumImagesPerEye("OCT").OD, getNumImagesPerEye("OCT").OS]}>
          <ImageScroll type="OCT" />
        </Tab>
        <Tab label={`FAF-2`} secondaryLabels={[getNumImagesPerEye("FAF").OD, getNumImagesPerEye("FAF").OS]}>
          <ImageScroll type="FAF" />
        </Tab>
        <Tab label={`FAF-1M`} secondaryLabels={[getNumImagesPerEye("FAFONH").OD, getNumImagesPerEye("FAFONH").OS]}>
          <ImageScroll type="FAFONH" />
        </Tab>
        <Tab label={`Properties`} secondaryLabels={[getNumImagesPerEye("Properties").OD, getNumImagesPerEye("Properties").OS]}>
          <ImageScroll type="Properties" />
        </Tab>
      </Tabs>

    </div>
  );
};
