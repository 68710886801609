import React, {
  useState,
  useRef,
  useEffect,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import * as common from '../../common';
import { WorkerPool } from "../workerPool";

import { Icon } from "@iconify/react";
import type { ImageFile } from "../../imageFiles";
import {
  requiredImageTypes,
  imageTypeKeywords,
  getDataTransferFiles,
} from "../../imageFiles";
import useWizardStore from "../stores/useWizardStore";
import useImageFilesStore from "../stores/ImageFilesStore";


export const UploadDropArea = ({
  onReady,
}) => {
  const importCaptureDate = useWizardStore(state => state.importCaptureDate);
  const setImportCaptureDate = useWizardStore(state => state.setImportCaptureDate);
  const imageFiles = useImageFilesStore(state => state.imageFiles);
  const updateImageFile = useImageFilesStore(state => state.updateImageFile);
  const addImageFiles = useImageFilesStore(state => state.addImageFiles);
  const clearImageFiles = useImageFilesStore(state => state.clearImageFiles);

  const [isWorking, setIsWorking] = useState(false);
  const workCount = useRef(0);
  const dropTotalsRef = useRef<any[]>([]);

  const poolSize = 2; // Choose the desired pool size
  const workerPool = useRef<WorkerPool | null>(null);

  const workerCallback = (e: MessageEvent) => {
    const { type, payload } = e.data;

    --workCount.current;
    unstable_batchedUpdates(() => {
      updateImageFile(payload);
      setIsWorking(workCount.current > 0);
    });
  };

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    onReady(false);
    const fileEntries = await getDataTransferFiles(e.dataTransfer.items);
    const droppedFiles = await Promise.all(fileEntries.map(f => 
      new Promise<File>(resolve => f.file(resolve))
    ));
    const acceptedFiles = droppedFiles
      .filter(f => supportedFileTypes.includes(f.type))
      .sort((a, b) => a.name.localeCompare(b.name));
    if (acceptedFiles.length < 1)
      return;

    const imgFiles = acceptedFiles.map(file => ({
      name: file.name.replace(/\.[^.]+$/, ""),
      // size: file.size,
      // file_type: file.type,
      type: null,
      eye: null,
      // capture_date: importCaptureDate || newCaptureDate,
      capture_date: null,
      study_id: null,
      patient_id: null,
      file: file,
      // lastModified: file.lastModified,
      // status: FileStatus.Pending,
    } as ImageFile));

    workCount.current += imgFiles.length;
    for (const img of imgFiles) {
      workerPool.current?.addTask({
        type: "scan",
        payload: img.name,
      });
    }

    unstable_batchedUpdates(() => {
      addImageFiles(imgFiles);
      setIsWorking(workCount.current > 0);
    });
  };


  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };


  useEffect(() => {
    workerPool.current = new WorkerPool("filename", poolSize, workerCallback);

    console.log("init worker pool");
    let offscreens: OffscreenCanvas[] = [];
    for (let i = 0; i < poolSize; i++) {
      const canvas = document.createElement("canvas");
      canvas.width = 496;
      canvas.height = 496;
      const offscreen = canvas.transferControlToOffscreen();
      offscreens.push(offscreen);
    }

    onReady(false);
    // Clean up on component unmount
    return () => {
      console.log("terminate worker pool");
      workerPool.current?.terminate();
    };
  }, []);

  function handleClickClear() {
    onReady(false);
    clearImageFiles();
  }

  const dropTotals: any[] = useMemo(() => {
    if (isWorking)
      return dropTotalsRef.current!;

    dropTotalsRef.current = [];
    for (const [k, v] of Object.entries(requiredImageTypes)) {
      for (const eye of ['OD', 'OS']) {
        dropTotalsRef.current.push({
          type: k,
          eye: eye,
          required: v,
          has: imageFiles.filter(i => i.type === k && i.eye === eye).length,
        });
      }
    }
    return dropTotalsRef.current;
  }, [imageFiles, isWorking]);

  useEffect(() => {
    const hasAllRequired = dropTotals.every(item => item.has >= item.required);
    onReady(hasAllRequired && importCaptureDate);
  }, [dropTotals, importCaptureDate]);

  const missingFilesTips = dropTotals
    .filter(item => item.has < item.required)
    .map(item => `${imageTypeKeywords[item.type]} ${item.eye} x${item.required - item.has}`);

  return (
    <div className="flex flex-col items-stretch w-full">
      <div
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDropCapture={onDragLeave}
        className={`relative flex flex-col justify-center items-center border-2 rounded-md p-4 gap-1 text-gray-800 transition-all duration-300 ease-in-out ${isDraggingOver
          ? "border-solid border-blue-600 bg-blue-100"
          : "border-dashed border-blue-300 bg-blue-50"
          }`}
      >
        <div className="text-xs font-semibold">
          {!importCaptureDate && <span className="text-red-600">* </span>}
          Image Capture Date
        </div>
        <div className="flex items-center space-x-2">
          <input
            type="date"
            // value={(importCaptureDate || newCaptureDate).slice(0, 10)}
            value={importCaptureDate.slice(0, 10)}
            onChange={(e) => setImportCaptureDate(e.target.value)}
            className="border-2 border-gray-300 rounded-md p-1 text-xs"
          />
        </div>
        <Icon
          icon="ic:baseline-upload-file"
          className={`w-16 h-16 transition-all duration-300 ease-in-out transform  ${isDraggingOver
            ? "-translate-y-2 text-blue-600"
            : "translate-y-0 text-blue-300"
            }`}
        />
        <div className="font-semibold flex flex-col items-center justify-center">

          <div className="font-bold text-lg text-blue-600">
            Images
          </div>
          <div className="flex justify-end ">
            <div className={`whitespace-nowrap ${isDraggingOver ? "max-w-0 opacity-0" : "max-w-[78px] opacity-100"} transition-all duration-300 ease-in-out overflow-hidden `}>
              Drag &&nbsp;
            </div>
            Drop to Import
          </div>

        </div>

        <div className="flex flex-row items-center gap-2">
          <div className="text-xs text-[#1f2937] font-semibold">
            {missingFilesTips.length > 0 && <span className="text-red-600">* </span>}
            {`${imageFiles.length} Files Added`}
          </div>
          <button onClick={handleClickClear} disabled={imageFiles.length == 0} className="bg-red-500 text-white text-sm p-2 rounded-md disabled:opacity-50">Clear All</button>
        </div>
        {imageFiles.length > 0 && <div className='absolute top-2 right-4 text-xs text-right text-red-600'>
          {!importCaptureDate && <p>* Select a capture date!</p>}
          {missingFilesTips.length > 0 && <p>* Missing required images:</p>}
          {missingFilesTips.map((tip, i) => <p key={i}>{tip}</p>)}
        </div>}
      </div>
    </div>
  );
};

const supportedFileTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/bmp",
];




export type SliderProps = {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  step?: number;
  label?: string;
  disabled?: boolean;
  percentageDone?: number;
};
export const Slider = ({
  min,
  max,
  value,
  onChange,
  step = 1,
  label,
  disabled = false,
  percentageDone,
}: SliderProps) => {
  return (
    <div className="flex items-center relative">
      <input
        id="slider"
        type="range"
        className="w-2/3 z-10"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        disabled={disabled}
      />
      <span className="w-1/3">{value}</span>

      <div className="absolute bottom-0 left-0 h-2 w-2/3">
        <div
          className="h-0.5 bg-green-500"
          style={{ width: `${percentageDone}%` }}
        ></div>
      </div>
    </div>
  );
};
