import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { db, getRedAndBscanSubImages } from "../../db";
import { Layer, Line, Stage, Image } from "react-konva";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import useWizardStore from "../stores/useWizardStore";
import { BScanData } from "../types/ImagingTypes";
import { unstable_batchedUpdates } from "react-dom";
import { Coord } from "../types/LayoutTypes";
import { Slider } from "./ImageTransform";
import { e } from "mathjs";
import Tooltip from "./Tooltip";
import { Icon } from "@iconify/react/dist/iconify.js";
import * as common from '../../common';

type OCTImagesProps = {
  capture_date: string;
  eye: "OD" | "OS";
  type: "OCT" | "OCTONH";
  onCalc: (data: BScanData) => void;
};

export const OCTImages = ({
  capture_date,
  eye,
  type,
  onCalc,
}: OCTImagesProps) => {
  const imageProperties = useWizardStore(state => state.imageProperties);
  const fovealPit = useWizardStore(state => state.fovealPit);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [posX, setPosX] = useState(0);
  const indexRef = useRef(0);
  const posRef = useRef(0);

  const mainDiv = useRef<HTMLDivElement>(null);
  const canvasEnfaceRef = useRef<Konva.Stage>(null);
  const canvasBscanRef = useRef<Konva.Stage>(null);
  const canvasZoomRef = useRef<Konva.Stage>(null);

  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  // Handle mouse move on the main stage
  const handleMouseMove = (e) => {
    const stage = canvasBscanRef.current;
    if (!stage) return;
    const mousePointTo = stage.getPointerPosition();
    if (!mousePointTo) return;
    setMousePos(mousePointTo);
  };

  // Render the content on the mini stage
  useEffect(() => {
    const zoomStage = canvasZoomRef.current;
    if (!zoomStage) return;

    const zoomLayer = zoomStage.getLayers()[0];
    if (!zoomLayer) return;

    const scalingFactor = canvasBscanRef.current!.scaleX(); // Define the scaling factor
    console.log("scalingFactor", scalingFactor);
    const zoomFactor = 2; // Define the zoom factor
    const zoomStageWidth = zoomStage.width();
    const zoomStageHeight = zoomStage.height();

    // Calculate the new position to center the zoom on the mouse position
    const zoomX = -mousePos.x * zoomFactor / scalingFactor + zoomStageWidth / 2;
    const zoomY = -mousePos.y * zoomFactor / scalingFactor + zoomStageHeight / 2;

    // Apply scaling and positioning to the zoom layer
    zoomLayer.scale({ x: zoomFactor, y: zoomFactor });
    zoomLayer.position({ x: zoomX, y: zoomY });

    // Redraw the layer to apply changes
    zoomLayer.batchDraw();
  }, [mousePos]);

  const [cachedImageData, setCachedImageData] = useState<{
    filepath: String;
    redBitmap: ImageBitmap;
    bscanBitmap: ImageBitmap;
  }[]>([]);


  useEffect(() => {
    window.addEventListener('resize', fitCanvas);
    if (fovealPit[eye]) {
      indexRef.current = fovealPit[eye].scanIndex;
      posRef.current = fovealPit[eye].scanX;
      setSelectedIndex(indexRef.current);
      setPosX(posRef.current);
    }
    initAsync();
    return () => window.removeEventListener('resize', fitCanvas);
  }, []);

  async function initAsync() {
    const paths = await db.metaWhereUser().filter(r =>
      r.capture_date == capture_date
      && r.eye == eye
      && r.type == type)
      .primaryKeys() as string[];
    paths.sort();
    const cached = await Promise.all(paths.map(getRedAndBscanSubImages));
    unstable_batchedUpdates(() => {
      setCachedImageData(cached);
    });
  }

  const bscanWH = useMemo(() => ({
    w: imageProperties![type][eye].sizeX,
    h: imageProperties![type][eye].sizeZ,
  }), [eye, type, imageProperties]);

  const clickHandler = useCallback((e: KonvaEventObject<MouseEvent>) => {
    const location = e.target.getStage()!.getPointerPosition();
    e.target.getStage()!.x(0);
    e.target.getStage()!.y(0);
    if (!location) return;
    const ip = imageProperties![type][eye]!;
    const scale = canvasBscanRef.current!.scaleX();
    const x = location.x / scale;
    posRef.current = x;
    const coord = convertFovealPitCoordinates(x / ip.sizeX, indexRef.current, cachedImageData.length, ip.IR.scanAngle, ip.patternSize.x, ip.patternSize.y);
    onCalc({
      totalScans: cachedImageData.length,
      scanIndex: indexRef.current,
      scanX: x,
      enfaceX: coord.x * ip.IR.sizeX,
      enfaceY: coord.y * ip.IR.sizeY,
    });
    setPosX(x);
    handleMouseMove(e);
  }, [eye, type, imageProperties, cachedImageData]);

  const sliderHandler = useCallback(e => {
    const index = parseInt(e.target.value);
    indexRef.current = index;
    setSelectedIndex(index);
  }, []);

  const fitCanvas = useCallback(() => {
    const aspect = bscanWH.h / bscanWH.w;
    const bscanCont = canvasBscanRef.current!.container();
    const enfaceCont = canvasEnfaceRef.current!.container();
    const flexW = Math.min(bscanWH.w, bscanCont.offsetWidth);
    const flexH = flexW * aspect;
    const scale = flexW / bscanWH.w;
    canvasBscanRef.current!.scale({ x: scale, y: scale });
    canvasEnfaceRef.current!.scale({ x: scale, y: scale });
    bscanCont.style.height = Math.trunc(flexH).toString() + 'px';
    enfaceCont.style.height = Math.trunc(flexH).toString() + 'px';
  }, []);

  useEffect(() => {
    if (cachedImageData.length < 1) return;
    canvasBscanRef.current!.container().style.overflow = 'hidden';
    fitCanvas();
  }, [cachedImageData]);

  if (cachedImageData.length < 1) {
    return (
      <div ref={mainDiv} className='section'>
        <div
          className="flex gap-1"
        // style={{
        //   aspectRatio: `${1536 + 496 + 4} / ${496 + 53 + 8}`,
        // }}
        >
          <div
            className={`flex-auto overflow-hidden`}

          >
            <div className="flex items-center justify-center bg-gray-200 text-xs text-center flex-shrink max-w-full w-[496px]"
              style={{ aspectRatio: `496 / 496` }}>Loading...</div>
          </div>
          <div
            className={`flex flex-col gap-2 flex-auto overflow-hidden`}

          >
            <div
              className="flex items-center justify-center bg-gray-200 text-xs text-center flex-shrink max-w-full w-[1024px]"
              style={{ aspectRatio: `1024 / 496` }}
            >Loading...</div>
            <Slider
              disabled={true}
              valueRenderer={(value) => (
                <div className="flex items-center justify-center gap-x-[5px] text-sm text-center pt-2 font-semibold">
                  Image Number
                  <div className="flex items-center justify-center px-[5px] py-[5px] rounded-md bg-blue-100 text-blue-600 font-bold">
                    <button>
                      <Icon icon="heroicons-outline:chevron-left" className="w-[15px] h-[15px]" />
                    </button>
                    <div className="w-[24px]">0</div>
                    <button>
                      <Icon icon="heroicons-outline:chevron-right" className="w-[15px] h-[15px]" />
                    </button>
                  </div>
                  of <span className="font-bold">0</span>
                  <Tooltip
                    content={
                      <>
                        Use the slider above to page through the scans to find the <span className="font-bold">Foveal Center Point</span>.
                      </>
                    }
                  >
                    <div>
                      <Icon icon="heroicons-outline:information-circle" className="w-[15px] h-[15px]" />
                    </div>
                  </Tooltip>
                </div>

              )}
              min={0}
              max={cachedImageData.length - 1}
              step={1}
              value={selectedIndex}
              setValue={setSelectedIndex}
              onChange={(value) => {
                sliderHandler({ target: { value: value } } as any);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  const ip = imageProperties![type][eye]!;
  const coord = convertFovealPitCoordinates(posX / ip.sizeX, selectedIndex, cachedImageData.length, ip.IR.scanAngle, ip.patternSize.x, ip.patternSize.y);
  const redX = coord.x * ip.IR.sizeX;
  const lineX = posX;

  return (
    <div ref={mainDiv} className='section flex gap-1'>
      <div className={`flex-auto overflow-hidden grow-[${bscanWH.h}]`}>
        <Stage
          ref={canvasEnfaceRef}
          width={bscanWH.h}
          height={bscanWH.h}
        >
          <Layer>
            <Image image={cachedImageData[selectedIndex].redBitmap} />
            <Line points={[redX, 0, redX, bscanWH.h]} stroke="lightgreen" opacity={0.5} />
          </Layer>
        </Stage>
      </div>
      <div className={`flex flex-col gap-2 flex-auto overflow-hidden grow-[${bscanWH.w}]`}>
        <Stage
          ref={canvasBscanRef}
          width={bscanWH.w}
          height={bscanWH.h}
          draggable
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
          onDragMove={clickHandler}
          onMouseDown={clickHandler}
          onMouseMove={handleMouseMove}
          style={{
            // disable canvas anti-aliasing
            imageRendering: 'pixelated',
          }}
        >
          <Layer listening={false}>
            <Image image={cachedImageData[selectedIndex].bscanBitmap} />
            <Line
              points={[lineX, 0, lineX, bscanWH.h]} stroke="lightgreen"
            />
          </Layer>



        </Stage>
        {/* Zoomed in view while dragging */}
        <Stage
          visible={isDragging}
          width={200} // Adjust size as needed
          height={150} // Adjust size as needed
          ref={canvasZoomRef}
          style={{
            border: '1px solid black',
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: 'white', // Optional: to make the zoomed view stand out
          }}
          listening={false} // Prevent interactions with the zoomed view
        >
          <Layer

          >
            {/* Same content as the main stage */}
            <Image image={cachedImageData[selectedIndex].bscanBitmap} />
            <Line points={[lineX, 0, lineX, bscanWH.h]} stroke="lightgreen" />
          </Layer>
        </Stage>


        {/* <input
          type="range"
          min={0}
          max={cachedImageData.length - 1}
          value={selectedIndex}
          onChange={sliderHandler}
        /> */}
        <Slider
          valueRenderer={(value) => (
            <div className="flex items-center justify-center gap-x-[5px] text-sm text-center pt-2 font-semibold">
              Image Number
              <div className="flex items-center justify-center px-[5px] py-[5px] rounded-md bg-blue-100 text-blue-600 font-bold">
                <button onClick={() => setSelectedIndex(selectedIndex - 1)} disabled={selectedIndex === 0}>
                  <Icon icon="heroicons-outline:chevron-left" className="w-[15px] h-[15px]" />
                </button>
                <div className="w-[24px]">{value + 1}</div>
                <button onClick={() => setSelectedIndex(selectedIndex + 1)} disabled={selectedIndex === cachedImageData.length - 1}>
                  <Icon icon="heroicons-outline:chevron-right" className="w-[15px] h-[15px]" />
                </button>
              </div>
              of <span className="font-bold">{cachedImageData.length}</span>
              <Tooltip
                content={
                  <>
                    Use the slider above to page through the scans to find the <span className="font-bold">Foveal Center Point</span>.
                  </>
                }
              >
                <div>
                  <Icon icon="heroicons-outline:information-circle" className="w-[15px] h-[15px]" />
                </div>
              </Tooltip>
            </div>

          )}
          min={0}
          max={cachedImageData.length - 1}
          step={1}
          value={selectedIndex}
          setValue={setSelectedIndex}
          onChange={(value) => {
            sliderHandler({ target: { value: value } } as any);
          }}
        />
        {/* <div className="text-xs text-center">
          Image {selectedIndex + 1} of {cachedImageData.length}
        </div> */}
      </div>
    </div>
  );
};

const convertFovealPitCoordinates = (
  x: number, // frac 0-1
  imageIndex: number,
  totalImages: number,
  enfaceWidthDeg: number,
  patternWidthDeg: number,
  patternHeightDeg: number,
): Coord => {
  const marginDeg = { x: (enfaceWidthDeg - patternWidthDeg) / 2, y: (enfaceWidthDeg - patternHeightDeg) / 2 };
  const scanBoxDeg = {
    left: marginDeg.x,
    right: enfaceWidthDeg - marginDeg.x,
    top: marginDeg.y,
    bottom: enfaceWidthDeg - marginDeg.y,
  };
  const xDeg = common.lerp1(x, scanBoxDeg.left, scanBoxDeg.right);
  const yDeg = common.lerp(imageIndex, 0, totalImages, scanBoxDeg.bottom, scanBoxDeg.top);
  return {
    x: xDeg / enfaceWidthDeg,
    y: yDeg / enfaceWidthDeg,
  };
};
